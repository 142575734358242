import React from 'react';

// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';
import Switch from 'react-switch';

import CustomButtons from './CustomTools';

const Container2 = styled.div`
  ${tw`
  mt-3
  rounded-lg
  pt-2
  relative
`}
`;

const Heading1 = styled.h1`
  ${tw`
  w-3/4
  text-2xl
  text-color-bright
  font-normal
  col-span-2
  pl-4
  text-left
`}

  font-size: 1.8rem;
  font-family: Refrigerator Deluxe extra bold;
`;

const Description = styled.div`
  ${tw`
  text-white
  w-3/4
  text-sm
  font-normal
  col-span-2
  py-2
  pl-8
  text-left
  mb-2
  leading-4
  `}
`;
const Section1 = styled.div`
  ${tw`
  flex
  `}
`;

const Section2 = styled.div`
  ${tw`
    pt-0
    px-1
    md:grid grid-cols-1 auto-cols-max
  `}
`;

const Toggle = styled.div`
  ${tw`
     pl-5
     pt-1.5
  `}
`;

const FormFillup = styled.input`
  width: 93%;
  padding: 12px 13px;
  margin: auto;
  margin-bottom: 10px;
  display: inline-block;
  border: 1px solid #666666;
  border-radius: 4px;
  box-sizing: border-box;
  color: #666666;
  font-size: 1rem;
`;
const Step = styled.img`
  ${tw`
   absolute
    h-24
    top-0
    left-0
    opacity-25
    `};
  filter: brightness(0) invert(1);
`;
const CustomText = ({
  userName,
  setUsername,
  checked,
  handleChange,
  fontFamily,
  fontColor,
  setFontColor,
  bgColor,
  setBgColor,
  alignment,
  setFontFamily,
  handleAlignment,
  align,
}) => (
  <Container2>
    <Step src='https://mobicheckin-assets.s3.eu-west-1.amazonaws.com/uploads/events/63ff5b42976ea6006b695226/website/assets-folder64ccf030de927e06e4296f30/2_l5urnc_5f5101c8-cf49-4569-b2b6-d7d9e7d7784b.png' />

    <Section1>
      {' '}
      <Heading1>ADD A CAPTION</Heading1>
      <label htmlFor='material-switch'>
        <Toggle>
          <Switch
            checked={checked}
            onChange={handleChange}
            onColor='#666666'
            onHandleColor='#ffffff'
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            height={13}
            width={30}
            className='react-switch'
            id='material-switch'
          />
        </Toggle>
      </label>
    </Section1>
    <Description>Write your text, move it or change its size using your computer mouse</Description>
    {checked && (
      <Section2>
        <FormFillup
          type='text'
          value={userName}
          name='username'
          placeholder='I’ll be at #BDAIP23'
          onChange={(e) => setUsername(e.target.value)}
        />
        <CustomButtons
          fontColors={fontColor}
          setFontColors={setFontColor}
          bgColors={bgColor}
          setBgColors={setBgColor}
          alignment={alignment}
          fontFamily={fontFamily}
          setFontFamily={setFontFamily}
          fontlist='list-font-name'
          handleAlignment={handleAlignment}
          align={align}
          FontId='custom-color-font-name'
          bgId='custom-color-bg-name'
        />
      </Section2>
    )}
  </Container2>
);

export default CustomText;
