import React from 'react';

// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Heading1 = styled.h1`
  ${tw`
  w-3/4
  text-2xl
  text-color-bright
  font-normal
  col-span-2
  pl-4
  text-left
  mt-5
`}

  font-size: 1.8rem;
  font-family: Refrigerator Deluxe extra bold;
`;
const Container = styled.div`
  ${tw`
    font-roboto
    grid
    grid-rows-1
    grid-cols-1
    relative
  `}
`;

const DownloadContainer = styled.div`
  ${tw`
    m-auto
    p-5
    
    `}
`;

const Button = styled.button`
  ${tw`
    uppercase
    rounded-full
    bg-color-bright
    px-12
    py-3
    text-black
    border-none
    outline-none
    hover:bg-frame-gray
    `};
    font-weight:800
`;
const Step = styled.img`
  ${tw`
   absolute
    h-24
    top-0
    left-0
    opacity-25
    `};
  filter: brightness(0) invert(1);
`;
const Download = ({ stageRef }) => {
  const downloadURI = (uri, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = () => {
    const dataURL = stageRef.current.toDataURL({ pixelRatio: 4 });
    downloadURI(dataURL, 'my-bdaip-selfie.png');
  };

  return (
    <Container>
      <Step src='https://mobicheckin-assets.s3.eu-west-1.amazonaws.com/uploads/events/63ff5b42976ea6006b695226/website/assets-folder64ccf030de927e06e4296f30/3_uoijfp_c38a5788-7f4a-44ae-8c32-698ae0e0cb51.png' />

      <p></p>
      <Heading1>DOWNLOAD YOUR PHOTO</Heading1>

      <DownloadContainer>
        <Button onClick={handleDownload}>
          {' '}
          <label htmlFor='contained-button-file'>
            <FontAwesomeIcon icon={faDownload} style={{ color: '#000', fontSize: '15px', marginRight:"5px" }} />
          </label>
          Save my picture
        </Button>
      </DownloadContainer>
    </Container>
  );
};

export default Download;
