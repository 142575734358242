import React, { useState, useRef } from 'react';

// Libraries
import useImage from 'use-image';
import styled from 'styled-components';
import tw from 'twin.macro';
import Helmet from 'react-helmet';
// Components
import Carousel from '../components/frameControllers/FrameCarousel';
import Inputs from '../components/frameControllers/DetailsInput';
import Download from '../components/toolbox/Download';
import Socials from '../components/toolbox/Socials';
import CanvasStage from '../components/canvas/Stage';
import Container from '../components/shared/Layout';

// Assets
import frameData from '../../config/frameData';
import FontUrl from '../../static/font/refrigerator_deluxe_bold.otf';

const CarouselC = styled.h1`
  ${tw`
    w-94
    w-full
    overflow-y-hidden
    mt-5
    bg-color-bright

`}
  margin: 0px, 0px;
  padding: 2px;
  overflow-x: auto;
  white-space: nowrap;
`;
const ContainerWrapper = styled.div`
  ${tw`
  grid-cols-2 
  md:grid-cols-1
  `};
  max-width: 1425px;
`;
const Wrapper = styled.div`
  ${tw`
flex-row	
m-auto
z-10
`}
`;

const FRAMES = {
  ONE: frameData.frames.ONE,
  TWO: frameData.frames.TWO,
};

const align = ['center', 'left', 'right'];
let i = 0;

const Frame = () => {
  const [selectedFrame, setSelectedFrame] = useState(FRAMES.ONE);
  const [uploadedImage, setUploadedImage] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [userName, setUserName] = useState('I’ll be at #BDAIT23');
  const [checked, setchecked] = useState(true);
  const [fontFamily, setFontFamily] = useState(null);
  const [bgColor, setBgColor] = useState(null);
  const [fontColor, setFontColor] = useState(null);
  const [alignment, setAlignment] = useState(align[0]);

  const handleAlignment = () => {
    setAlignment(align[i + 1]);
    // eslint-disable-next-line no-const-assign
    i += 1;

    if (i === 3) {
      setAlignment(align[i - 3]);
      i = 0;
    }
  };
  const handleChange = () => setchecked((prevCheck) => !prevCheck);

  const stageRef = useRef(null);
  const [frameImg] = useImage(selectedFrame, 'Anonymous');
  const [image] = useImage(uploadedImage, 'Anonymous');

  return (
    <>
      <Helmet>
        <title>Selfie Generator | Big Data & AI Toronto 2023</title>
        <meta
          name='Selfie Generator | Big Data & AI Toronto 2023'
          contect='Customiiiiiize your selfie with the colors of Big Data & AI Toronto 2023 and share it on your social media!'
        />
        <link rel='preload' href={FontUrl} as='font' crossorigin='anonymous' type='font/otf' />
      </Helmet>

      <Container>
        <Wrapper>
          {typeof window !== 'undefined' && (
            <CanvasStage
              stageRef={stageRef}
              userName={userName}
              frameImg={frameImg}
              image={image}
              alignment={alignment}
              fontColor={fontColor}
              checked={checked}
              fontFamily={fontFamily}
              bgColor={bgColor}
              height={height}
              width={width}
            />
          )}
          <CarouselC>
            <Carousel frames={FRAMES} setSelectedFrame={setSelectedFrame} />
          </CarouselC>
        </Wrapper>

        <Wrapper>
          <Inputs
            handleChange={handleChange}
            checked={checked}
            setchecked={setchecked}
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
            userName={userName}
            setUsername={setUserName}
            fontFamily={fontFamily}
            setFontFamily={setFontFamily}
            bgColor={bgColor}
            setBgColor={setBgColor}
            fontColor={fontColor}
            setFontColor={setFontColor}
            alignment={alignment}
            handleAlignment={handleAlignment}
            align={align}
            setHeight={setHeight}
            setWidth={setWidth}
          />

          <Download stageRef={stageRef} />
          <Socials />
        </Wrapper>
      </Container>
    </>
  );
};

export default Frame;
