import React from 'react';

// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

const Heading1 = styled.h1`
  ${tw`
  w-3/4
  text-2xl
  text-color-bright
  font-normal
  col-span-2
  pl-4
  text-left
  mt-5
`}

  font-size: 1.8rem;
  font-family: Refrigerator Deluxe extra bold;
`;
const Container = styled.div`
  ${tw`
    font-roboto
    grid
    grid-rows-1
    grid-cols-1
    relative
  `}
`;

const ButtonContainer = styled.div`
  ${tw`
  inline-block	mr-2
    `}
`;

const Button = styled.button`
  ${tw`
    uppercase
    rounded-full
    bg-color-bright
    px-14
    py-6
    text-black
    border-none
    outline-none
    hover:bg-frame-gray
    `}
`;
const Step = styled.img`
  ${tw`
   absolute
    h-24
    top-0
    left-0
    opacity-25
    `};
  filter: brightness(0) invert(1);
`;
const Socials = () => {
  return (
    <Container>
      <Step src='https://mobicheckin-assets.s3.eu-west-1.amazonaws.com/uploads/events/63ff5b42976ea6006b695226/website/assets-folder64ccbd96db606c001d7410b4/kindpng_1175386_f7d2f748-7857-4e38-b541-57e0af3494fc.png' />

      <Heading1>SHARE IT ON YOUR SOCIAL MEDIA</Heading1>
      <div>
        <ButtonContainer>
          <FacebookShareButton
            url={'https://www.bigdataparis.com/'}
            quote={'I will attend Big Data & AI Toronto 2023'}
            hashtag='#BDAIT23'
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </ButtonContainer>
        <ButtonContainer>
          <TwitterShareButton
            url={'https://www.bigdataparis.com/'}
            quote={'I will attend Big Data & AI Toronto 2023'}
            hashtag='#BDAIT23'
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </ButtonContainer>
        <ButtonContainer>
          <LinkedinShareButton
            url={'https://www.bigdataparis.com/'}
            quote={'I will attend Big Data & AI Toronto 2023'}
            hashtag='#BDAIT23'
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </ButtonContainer>
      </div>
    </Container>
  );
};

export default Socials;
